import { axiosClient } from '@/services/axios-client';

const acceptPermissions = (userId, clientId, scopes, code) => {
    const body = {
        user_id: userId,
        client_id: clientId,
        accepted_scopes: scopes,
        code,
    };
    return axiosClient.post('/auth/authorize', body);
};

const saveData = (params) => sessionStorage.setItem('authorize_params', JSON.stringify(params));

const getData = () => JSON.parse(sessionStorage.getItem('authorize_params'));

const removeData = () => sessionStorage.removeItem('authorize_params');

const PermissionsClient = {
    acceptPermissions,
    saveData,
    getData,
    removeData,
};

export { PermissionsClient };