<template>
  <div class="authorize-consents">
    <div class="authorize-consents__header">
      <img
        class="authorize-consents__logo"
        :src="clientIcon"
        alt="App logo"
      >
      <h2 class="authorize-consents__title">
        {{ clientName }}
      </h2>
      <p class="authorize-consents__text">
        {{ $t('authorize-consents.subtitle') }}
      </p>
      <p class="authorize-consents__user">
        <span class="authorize-consents__user-email">
          {{ `${hiddenEmail} -` }}
        </span>

        <span
          class="authorize-consents__user-link"
          @click="logout"
        >
          {{ $t('authorize-consents.not-you') }}
        </span>
      </p>
    </div>
    <div class="authorize-consents__content">
      <p class="authorize-consents__content-title">
        {{ $t('authorize-consents.list-title', {app: clientName}) }}
      </p>
      <div class="authorize-consents__list">
        <div
          v-for="scope in scopes"
          :key="scope.id"
          class="authorize-consents__consent"
        >
          <base-icon
            :name="scopeIcons[scope]"
            size="medium"
          />
          <div
            class="authorize-consents__consent-text"
          >
            <p class="authorize-consents__consent-title">
              {{ $t(`authorize-consents.${scope}`) }}
            </p>
            <p class="authorize-consents__smalltext">
              {{ $t(`authorize-consents.${scope}-consents`) }}
            </p>
          </div>
        </div>
      </div>
      <hr>
      <div class="authorize-consents__warning">
        {{ $t('authorize-consents.warning', {app: clientName}) }}
      </div>
      <hr>
      <div class="authorize-consents__buttons">
        <base-button @onClick="cancelConsents">
          {{ $t('authorize-consents.cancel-button') }}
        </base-button>
        <base-button
          :is-type="['install', 'simple']"
          @onClick="acceptPermissions"
        >
          {{ $t('authorize-consents.authorize-button') }}
        </base-button>
      </div>
    </div>
    <div class="authorize-consents__outside">
      <p class="authorize-consents__copyright">
        &copy; 2021 Voicemod S.L.
      </p>
      <div class="authorize-consents__links">
        <a
          href="http://www.voicemod.net/privacy"
          target="blank"
          class="authorize-consents__link"
        >
          {{ $t('authorize-consents.privacy-link') }}
        </a>
        <a
          href="http://www.voicemod.net/cookies-policy"
          target="blank"
          class="authorize-consents__link"
        >
          {{ $t('authorize-consents.cookies-link') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { AuthClient } from '@/services/models/authentication';
import { PermissionsClient } from '@/services/models/permissions';
import { CookieService} from '@/services/cookie';
import { sessionServiceDomain } from '@/domain/session';
import { LOGIN_ACTIONS_CONSTANTS } from '@/domain/login';
import { ROUTER_PATHS } from '@/router/paths';
import voicemodLogo from 'images/voicemod-blue.png';

export default {
    components: {
        BaseIcon: () => import(/* webpackChunkName: "BaseIcon"*/ '@RepoComponent/BaseIcon/BaseIcon'),
        BaseButton: () => import(/* webpackChunkName: "BaseButton"*/ '@RepoComponent/BaseButton/BaseButton'),
    },
    data() {
        return {
            clientName: null,
            userId: null,
            clientId: null,
            scopes: null,
            code: null,
            url: null,
            iconUrl: '',
            scopeIcons: {
                profile: LOGIN_ACTIONS_CONSTANTS.ACCOUNT,
                list_soundboards: 'soundboard',
            },
        };
    },
    computed: {
        userInfo() {
            return JSON.parse(sessionStorage.getItem('userInfo'));
        },
        hasValidSession() {
            return CookieService.getCookie('refresh_token');
        },
        hasValidParams() {
            const { client, url, userId, id } = this.$route.query;
            return client && url && userId && id;
        },
        hiddenEmail() {
            const [emailName, emailDomain] = this.userInfo.email.split('@');
            const hiddenName = `${emailName.charAt(0)}*****${emailName.charAt(emailName.length - 1)}`;

            return `${hiddenName}@${emailDomain}`;
        },
        clientIcon() {
            if(this.iconUrl.length) {
                return this.iconUrl;
            }

            return voicemodLogo;
        },
    },
    async beforeMount() {
        if(!this.hasValidSession || !this.hasValidParams) {
            this.redirectToLogin();
            return;
        }

        const permissionsInfo = await this.getPermissions();

        if(this.isAlreadyAuthorized(permissionsInfo)) {
            this.authorizedRedirect(permissionsInfo.code);
            return;
        }

        if(permissionsInfo) {
            this.setPermissionsRequestInfo(permissionsInfo);
        }

        PermissionsClient.removeData();
    },
    methods: {
        redirectToLogin() {
            this.$router.push({
                path: ROUTER_PATHS.HOME,
                query: {
                    action: LOGIN_ACTIONS_CONSTANTS.ACCOUNT,
                },
            });
        },
        async getPermissions() {
            const { client, userId } = this.$route.query;

            const { data: permissionsData } = await AuthClient.generateThirdPartyCode(userId, client);

            return permissionsData;
        },
        isAlreadyAuthorized(permissionsData) {
            return permissionsData.isAuthorized;
        },
        authorizedRedirect(code) {
            const { url } = this.$route.query;
            window.location.assign(`${url}?code=${code}`);
        },
        setPermissionsRequestInfo({ clientScopes, clientIconURL, code }) {
            const { client, url, userId, id } = this.$route.query;
            this.clientName = client.toUpperCase();
            this.userId = userId;
            this.clientId = id;
            this.scopes = clientScopes;
            this.code = code;
            this.url = url;
            this.iconUrl = clientIconURL;
        },
        async acceptPermissions() {
            await PermissionsClient.acceptPermissions(this.userId, this.clientId, this.scopes, this.code);

            window.location.assign(`${this.url}?code=${this.code}`);
        },
        cancelConsents() {
            const referrerURL = sessionStorage.getItem('referrer');

            if(referrerURL) {
                window.location.assign(referrerURL);
                return;
            }

            this.logout();
        },
        logout() {
            sessionServiceDomain.userLogout();
            this.$router.push({
                path: ROUTER_PATHS.HOME,
                query: {
                    action: LOGIN_ACTIONS_CONSTANTS.AUTHORIZE,
                    ...this.$route.query,
                },
            });
        },
    },

};
</script>

<style src="./AuthorizeConsentsView.scss" lang="scss" scoped></style>